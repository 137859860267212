import {
  checkout,
  getCart,
  getDonationCategory,
  updateLineItem,
} from '@/cart/cart-store';
import {CartSectionSettings} from '@/cart/types';
import CartSection from '@/components/cart/CartSection';
import {logError, logInfo} from '@/utilities/log';
import {Plan} from '@rechargeapps/storefront-client';

export default CartView;

/**
 * This component is a wrapper around [CartSection] that allows you to
 * specify the settings handle to use. It consumes the cart and settings
 * from the cart-store and cart-settings modules, respectively.
 * These are passed to [CartSection] so that it can be used as a standalone
 * component.
 */
function CartView({settings}: CartViewProps) {
  const cart = getCart();

  /**
   * Update the quantity of a line item in the cart.
   */
  const handleQuantityUpdate = async (line: number, quantity: number) => {
    try {
      await updateLineItem({
        line,
        quantity,
      });
    } catch (error) {
      logError(error, {line, quantity});
    }
  };

  /**
   * Save the donation category to the cart attributes.
   */
  const handleDonationUpdate = async (option: string) => {
    try {
      await setDonationCategory(option);
    } catch (error) {
      logError(error, {donationCategory: option});
    }
  };

  const handleCheckout = async () => {
    try {
      // If the donation category is not set, default to the first option
      const category =
        getDonationCategory() ?? settings.donation_select_options.options[0];
      await setDonationCategory(category);
      await checkout();
    } catch (error) {
      logError(error);
    }
  };

  const handleUpdateSellingPlan = async (line: number, plan: Plan | null) => {
    try {
      const item = cart?.items[line - 1];

      await updateLineItem({
        line,
        selling_plan: plan?.external_plan_id ?? undefined,
        quantity: item?.quantity ?? 1,
      });
    } catch (error) {
      logError(error, {line, sellingPlan: plan});
    }
  };

  return (
    <CartSection
      cart={cart}
      onCheckout={handleCheckout as () => void}
      onDonationUpdate={handleDonationUpdate}
      onQuantityUpdate={handleQuantityUpdate}
      onSellingPlanUpdate={handleUpdateSellingPlan}
      settings={settings}
    />
  );
}

type CartViewProps = {
  settings: CartSectionSettings;
};

/**
 * Sets the donation category for the items in the cart.
 *
 * @param category - The donation category to set.
 * @returns A promise that resolves when the donation category is successfully updated.
 * @throws If there is an error updating the donation category.
 */
async function setDonationCategory(category: string) {
  const cart = getCart();
  if (!cart) return;
  try {
    await Promise.all(
      cart.items.map((item) =>
        updateLineItem({
          id: item.id.toString(),
          // quantity must be specified, otherwise Shopify resets it to 1
          quantity: item.quantity,
          properties: {
            _buy_one_give_ten_category: category,
          },
        })
      )
    );
    logInfo('DonationCategoryUpdated', {category, cart});
  } catch (error) {
    logError(error, {message: 'ErrorUpdatingDonationCategory', category, cart});
    throw error;
  }
}
