import {CartSectionSettings, cartSectionSettingsSchema} from '@/cart/types';
import CartView from '@/components/cart/CartView';
import placeholderCartSectionSettings from '@/settings/placeholder/cart-section';
import {logError} from '@/utilities/log';
import register from 'preact-custom-element';

register(Loader, 'cart-view', ['metaobject']);

/**
 * Wrapper around [CartView] that loads and parses the metaobject settings data.
 * This allows us to pass in JSON data from liquid to the cart-view custom element.
 */
function Loader({metaobject}: {metaobject?: string}) {
  const settings = metaobject ? parseMetaobject(metaobject) : null;
  return <CartView settings={settings ?? placeholderCartSectionSettings} />;
}

/**
 * Parse the metaobject JSON string into a metaobject object.
 * If parsing fails, log an error and return null.
 *
 * @param jsonString - The JSON string to parse
 * @returns The parsed metaobject or null if parsing failed
 */
function parseMetaobject(jsonString: string): CartSectionSettings | null {
  try {
    return cartSectionSettingsSchema.parse(JSON.parse(jsonString));
  } catch (error) {
    logError(error, {message: 'Failed to parse cart settings metaobject'});
    return null;
  }
}
