import ComponentToggleSignal from '@/components/component-toggle-signal';
import LocaleDialog from '@/components/locale-dialog/LocaleDialog';
import {countrySchema, shopLocaleSchema} from '@/localization/types';
import {logError} from '@/utilities/log';
import register from 'preact-custom-element';
import {useEffect, useMemo} from 'preact/hooks';
import {z, ZodError} from 'zod';

export type LocaleDialogLoaderProps = {
  bodyText: string;
  countryLabel: string;
  countryOptions: string;
  languageLabel: string;
  languageOptions: string;
  selectedCountry: string;
  selectedLanguage: string;
  submitButtonLabel: string;
  title: string;
};

const navItemSelector = '.locale-nav-item';

/**
 * A loader for the locale dialog.
 * Parses the arguments from JSON and renders the dialog.
 * Handles the opening and closing of the dialog,
 * and updates the locale when a country or language is selected.
 * Intended to be used as a custom element.
 *
 * @param countryLabel - The label for the country selector.
 * @param countryOptions - The available country options (JSON array string).
 * @param languageLabel - The label for the language selector.
 * @param languageOptions - The available language options (JSON array string).
 * @param selectedCountry - The currently selected country.
 * @param selectedLanguage - The currently selected language.
 * @param submitButtonLabel - The label for the submit button.
 * @param title - The title of the dialog.
 * @returns The locale dialog loader.
 */
export default function LocaleDialogLoader({
  bodyText,
  countryLabel,
  countryOptions: countryOptionsJson,
  languageLabel,
  languageOptions: languageOptionsJson,
  selectedCountry,
  selectedLanguage,
  submitButtonLabel,
  title,
}: LocaleDialogLoaderProps) {
  const dialogController = useMemo(() => new ComponentToggleSignal(), []);

  useEffect(() => {
    const navItems = document.querySelectorAll(navItemSelector);
    const handleClick = (e: Event) => {
      e.preventDefault();
      dialogController.open();
    };

    for (const item of navItems) {
      item.addEventListener('click', handleClick);
    }

    return removeEventListener('click', handleClick);
  }, [dialogController]);

  const countryOptions = parseCountryOptions(countryOptionsJson);
  const languageOptions = parseLanguageOptions(languageOptionsJson);

  if (!countryOptions || !languageOptions) {
    return null;
  }

  return (
    <LocaleDialog
      bodyText={bodyText}
      controller={dialogController}
      countryLabel={countryLabel}
      countryOptions={countryOptions}
      languageLabel={languageLabel}
      languageOptions={languageOptions}
      selectedCountry={selectedCountry}
      selectedLanguage={selectedLanguage}
      submitButtonLabel={submitButtonLabel}
      title={title}
    />
  );
}

function parseCountryOptions(countryOptions: string) {
  try {
    return z.array(countrySchema).parse(JSON.parse(countryOptions));
  } catch (error) {
    logError(error, {
      message: 'ErrorParsingCountryOptions',
      issues: error instanceof ZodError ? error.issues : null,
    });
    return null;
  }
}

function parseLanguageOptions(languageOptions: string) {
  try {
    const schema = z.array(shopLocaleSchema);
    return schema.parse(JSON.parse(languageOptions));
  } catch (error) {
    logError(error, {
      message: 'ErrorParsingLanguageOptions',
      issues: error instanceof ZodError ? error.issues : null,
    });
    return null;
  }
}

register(LocaleDialogLoader, 'locale-dialog', [
  'bodyText',
  'countryOptions',
  'selectedCountry',
  'languageOptions',
  'selectedLanguage',
  'countryLabel',
  'languageLabel',
  'submitButtonLabel',
  'title',
]);
