import {Fragment, ComponentChildren} from 'preact';

/**
 * This component displays a grid of summary lines. Each line consists
 * of an icon, a label, and a value.
 * The value can be a string, JSX element or null.
 * If null, a skeleton loader will be displayed in its place.
 * @see [CartSection]
 */
export default function SummaryGrid({
  lines,
  showSeparator,
  className,
}: SummaryGridProps) {
  return (
    <div
      className={`grid-cols-grow-max grid items-center gap-x-2 gap-y-1 px-2 py-1 ${className}`}
    >
      {lines.map(({label, value}) => (
        <Fragment key={label}>
          <span className="text-base text-dark-true-black">{label}</span>
          {value ? (
            <span className="flex justify-end text-base font-bold">
              {value}
            </span>
          ) : (
            <div className="skeleton m-0.75 h-5 w-12 rounded"></div>
          )}
          {showSeparator && <hr className="col-span-full" />}
        </Fragment>
      ))}
    </div>
  );
}

export type SummaryGridProps = {
  lines: SummaryGridLine[];
  showSeparator?: boolean;
  className?: string;
};

export type SummaryGridLine = {
  label: ComponentChildren;
  value: ComponentChildren;
};
