import QuantitySelector from '@/components/QuantitySelector';
import closeIcon from '@/icons/close-icon.svg';

export default PlaceholderLineItem;

/**
 * PlaceholderLineItem component.
 * Renders a placeholder for a line item in a shopping cart or similar.
 * This is a skeleton loader displayed while the actual line item is loading.
 */
function PlaceholderLineItem() {
  return (
    <div className="grid grid-cols-[minmax(0,1fr)_minmax(0,_2fr)] items-center gap-x-3 px-6 py-3">
      <div className="skeleton col-span-1 row-start-1 row-end-5 mb-3 aspect-[2/3] w-full self-start rounded"></div>
      <div>
        <div className="grid-cols-grow-max grid items-start gap-x-1">
          <div className="grid">
            <div className="skeleton my-0.75 h-5 w-60 max-w-full rounded"></div>
          </div>
          <button className="btn btn-neutral rounded-full p-0">
            <img
              src={closeIcon}
              alt="Remove item"
              height="21"
              width="21"
              className="size-5"
            />
          </button>
        </div>
        <div className="skeleton my-0.75 h-3.5 w-32 max-w-full rounded"></div>

        <h6 className="flex items-center gap-2">
          <div className="skeleton my-0.75 h-4 w-14 max-w-full rounded"></div>
        </h6>
      </div>

      <div className="row-span-3"></div>

      <QuantitySelector value={1} />
      <div className="flex h-full items-end">
        <div className="skeleton my-0.75 h-2.5 w-18 max-w-full rounded"></div>
      </div>
    </div>
  );
}
