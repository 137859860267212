import {h} from 'preact';
import register from 'preact-custom-element';

import RebrandNewsletterForm , { type NewsletterProps as RebrandNewsletterProps } from '@/components/KlaviyoNewsletterFormRebrand';
import NewsletterForm, { type NewsletterProps } from '@/components/KlaviyoNewsletterForm';

const klaviyoListId = `${import.meta.env.VITE_PUBLIC_KLAVIYO_LIST_ID}`;
const klaviyoCompanyId = `${import.meta.env.VITE_PUBLIC_KLAVIYO_COMPANY_ID}`;
const klaviyoRevisionVersion = '2023-12-15';
const baseURL = 'https://a.klaviyo.com/client/subscriptions?company_id='

/**
 * Gets the current timezone offset in hours.
 * 
 * @returns The timezone offset from UTC, in hours. Positive numbers represent timezones east of UTC, 
 * negative numbers represent timezones west of UTC.
 */
const getTimezoneOffset = () => {
  return -new Date().getTimezoneOffset() / 60;
};

const getClientSubKlaviyo = (email: string) => {
  const payload = {
    data: {
      type: "subscription",
      attributes: {
        custom_source: "Newsletter signup",
        profile: {
          data: {
            type: "profile",
            attributes: {
              email
            },
            properties: {
              'services': "{\"shopify\":{\"source\":\"form\"}}",
              '$timezone_offset': getTimezoneOffset(),
            }
          },
        }
      },
      relationships: {
        list: {
          data: {
            type: "list",
            id: klaviyoListId,
          }
        }
      }
    }
  };

  const options = {
    method: 'POST',
    headers: {revision: klaviyoRevisionVersion, 'content-type': 'application/json'},
    body: JSON.stringify(payload)
  };

  const url = `${baseURL}${klaviyoCompanyId}`;

  return fetch(url, options)
}


const renderNewsletterForm = ({invalidEmailErr, newsletterReqErr, successMsg}: NewsletterProps) => 
    h(NewsletterForm, { 
        invalidEmailErr,
        newsletterReqErr,
        successMsg,
        onFormSubmit: (email: string) => getClientSubKlaviyo(email)
    })

register(
    renderNewsletterForm,
    'klaviyo-newsletter-form',
    ['invalidEmailErr', 'newsletterReqErr', 'successMsg']
);
  
const renderRebrandNewsletterForm = ({invalidEmailErr, newsletterReqErr, successMsg}: RebrandNewsletterProps) => 
    h(RebrandNewsletterForm, { 
        invalidEmailErr,
        newsletterReqErr,
        successMsg,
        onFormSubmit: (email: string) => getClientSubKlaviyo(email)
    })

register(
    renderRebrandNewsletterForm,
    'rebrand-newsletter-form',
    ['invalidEmailErr', 'newsletterReqErr', 'successMsg']
);
