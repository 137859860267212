import {z} from 'zod';

/**
 * Represents the response after adding line items to a cart.
 * This is returned by Shopify's Ajax Cart API when adding line items.
 */
export type AddLineItemsResponse = {
  items: CartLineItem[];
};

/**
 * Represents a shopping cart.
 * This is analagous to the Cart Ajax API response,
 * but it also can be used for the liquid cart object.
 */
export type Cart = {
  attributes: Record<string, string>;
  cart_level_discount_applications: DiscountApplication[];
  checkout_charge_amount?: number;
  currency: string;
  item_count: number;
  items: CartLineItem[];
  items_subtotal_price: number;
  note: string | null;
  original_total_price: number;
  requires_shipping: boolean;
  token?: string;
  total_discount: number;
  total_price: number;
  total_weight: number;
};

/**
 * Represents a line item in a shopping cart.
 */
export type CartLineItem = {
  discounted_price: number;
  discounts: Discount[];
  featured_image: Image;
  final_line_price: number;
  final_price: number;
  gift_card: boolean;
  grams: number;
  handle: string;
  has_components?: boolean;
  id: number;
  image: string;
  key: string;
  line_level_discount_allocations: DiscountAllocation[];
  line_level_total_discount: number;
  line_price: number;
  options_with_values: Option[];
  original_line_price: number;
  original_price: number;
  price: number;
  product_description: string;
  product_has_only_default_variant: boolean;
  product_id: number;
  product_title: string;
  product_type: string;
  properties: Record<string, string> | null;
  quantity: number;
  quantity_rule?: QuantityRule;
  requires_shipping: boolean;
  selling_plan_allocation?: SellingPlanAllocation;
  sku: string;
  taxable: boolean;
  title: string;
  total_discount: number;
  untranslated_product_title?: string;
  untranslated_variant_title?: string;
  url: string;
  variant_id: number;
  variant_options: string[];
  variant_title: string;
  vendor: string;
};

/**
 * Represents the adjustment to the price of a line item
 * due to the selling plan applied to it.
 */
type PriceAdjustment = {
  order_count?: null;
  position: number;
  price?: number;
  value?: number;
  value_type?: string;
};

/**
 * Represents a selling plan for a product variant.
 * This is analagous to the response type in Shopify's Cart Ajax API.
 */
export type SellingPlan = {
  description: null | string;
  fixed_selling_plan?: boolean;
  id: number;
  name: string;
  options: Option[];
  price_adjustments: PriceAdjustment[];
  recurring_deliveries: boolean;
};

/**
 * Represents the allocation of a selling plan to a line item.
 */
export type SellingPlanAllocation = {
  checkout_charge_amount?: number;
  compare_at_price: number;
  per_delivery_price: number;
  price: number;
  price_adjustments: PriceAdjustment[];
  remaining_balance_charge_amount?: number;
  selling_plan: SellingPlan;
};

/**
 * The Shopify metaobject that contains [DonationSelectorOptions].
 * @param id - The id of the metaobject
 * @param handle - The handle of the metaobject
 * @param options - The options to display in the donation selector
 */
export type DonationSelectorOptionsMetaobject = {
  handle: string;
  id: string;
  options: string;
};

/**
 * Represents a discount applied to a cart or line item.
 */
export type Discount = {
  target_selection: 'all' | 'entitled' | 'explicit';
  target_type: 'line_item' | 'shipping_line';
  title: string;
  total_allocated_amount: number;
  type: 'automatic' | 'discount_code' | 'manual' | 'script';
  value: number;
  value_type: 'FixedAmountDiscount' | 'PercentageDiscount' | 'ShippingDiscount';
};

/**
 * Represents the allocation of a discount to a line item or shipping line.
 */
export type DiscountAllocation = {
  amount: number;
  discount_application: DiscountApplication;
};

/**
 * Represents a discount application to a cart or line item.
 */
export type DiscountApplication = {
  target_selection: 'all' | 'entitled' | 'explicit';
  target_type: 'line_item' | 'shipping_line';
  title: string;
  total_allocated_amount: number;
  type: 'automatic' | 'discount_code' | 'manual' | 'script';
  value: number;
  value_type: 'fixed_amount' | 'percentage';
};

/**
 * Represents an image associated with a product or variant.
 * This is analagous to the response type in Shopify's Cart Ajax API.
 */
export type Image = {
  alt: string;
  aspect_ratio: number;
  height: number;
  url: string;
  width: number;
};

/**
 * Represents the input for a line item in a cart.
 * This is used to add items to a cart.
 * If `properties` or `selling_plan` are not included, they will not be set on the line item.
 */
export type LineItemInput = {
  id: number;
  properties?: Record<string, string>;
  quantity: number;
  selling_plan?: number;
};

/**
 * Represents the updated information for a line item in the cart.
 * This is returned by Shopify's Ajax Cart API when updating line items.
 */
export type LineItemUpdate = {
  id: number;
  image: string;
  price: string;
  product_id: number;
  product_type: string;
  quantity: number;
  sku: string;
  title: string;
  untranslated_product_title: string;
  untranslated_variant_title: string;
  variant_id: number;
  vendor: string;
  view_key: string;
};

/**
 * Represents an option for a product variant.
 */
export type Option = {
  name: string;
  position?: number;
  value: string;
};

/**
 * Represents a rule for the quantity of a line item in a cart.
 */
export type QuantityRule = {
  increment: number;
  max: number | null;
  min: number;
};

/**
 * Represents the input for updating a cart.
 * This is used to change the quantity of multiple items at once,
 * or to add a note or attributes to the cart.
 */
export type UpdateCartInput = {
  attributes?: Record<string, string>;
  note?: string;
  updates?: Record<string, number>;
};

/**
 * Represents the input for updating a line item in a cart.
 * May include either an `id` or a `line` number, but not both.
 * If a parameter is not included, it will not be updated.
 * @param id - The id of the line item to update
 * @param line - The line number of the line item to update
 * @param quantity - The new quantity of the line item
 * @param properties - The new properties of the line item
 * @param selling_plan - The new selling plan of the line item
 */
export type UpdateLineItemInput =
  | UpdateLineItemInputWithId
  | UpdateLineItemInputWithLine;

type UpdateLineItemInputWithId = UpdateLineItemInputBase & {
  id: string;
  line?: never;
};

type UpdateLineItemInputWithLine = UpdateLineItemInputBase & {
  id?: never;
  line: number;
};

type UpdateLineItemInputBase = {
  properties?: Record<string, string>;
  quantity?: number;
  selling_plan?: number | null;
};

/**
 * Represents the response after updating a line item in a cart.
 */
export type UpdateLineItemResponse = Cart & {
  items_added: LineItemUpdate[];
  items_removed: LineItemUpdate[];
};

/**
 * Represents the donation options metaobject from Shopify.
 * This is used to parse the options from the metaobject data.
 *
 */
const donationOptionsSchema = z.object({
  options: z.array(z.string()),
});

/**
 * Settings object for DonationSelector component
 * The option selected will become an attribute on the cart at checkout.
 * @param options - The options to display in the selector
 */
export type DonationSelectorOptions = z.infer<typeof donationOptionsSchema>;

/**
 * Represents the settings for a cart section.
 * This is used to parse the metaobject data from liquid.
 */
export const cartSectionSettingsSchema = z.object({
  cart_title: z.string(),
  checkout_button_label: z.string(),
  donation_heading: z.string(),
  donation_select_label: z.string(),
  donation_select_options: donationOptionsSchema,
  donation_terms_conditions: z.string(),
  empty_cart_title: z.string(),
  free_shipping: z.string(),
  shipping_line_label: z.string(),
  subtotal_line_label: z.string(),
});

/**
 * Represents the settings for a cart section.
 * This may be implemented on Shopify using metaobjects.
 */
export type CartSectionSettings = z.infer<typeof cartSectionSettingsSchema>;

/**
 * Represents the base settings for a cart section.
 * This may be implemented on Shopify using metaobjects.
 */
type CartSectionSettingsBase = {
  cart_title: string;
  checkout_button_label: string;
  donation_heading: string;
  donation_select_label: string;
  donation_terms_conditions: string;
  empty_cart_title: string;
  free_shipping: string;
  shipping_line_label: string;
  subtotal_line_label: string;
};

/**
 * Represents the response after fetching the settings for a cart section.
 */
export type CartSectionSettingsResponse = CartSectionSettingsBase & {
  donation_select_options: string;
};
