import {effect, signal} from '@preact/signals';

import {getCart} from '@/cart/cart-store';
import type {CartLineItem} from '@/cart/types';
import {getVariantMetafieldValue} from '@/product/get-variant-metafield-value';
import {logError} from '@/utilities/log';

const pricePerLoadMap = signal<Map<number, number | null>>(new Map());

effect(() => {
  const cart = getCart();
  if (cart === null) return;

  const handleError = (error: Error) => {
    logError(error, {cart});
  };
  updatePricePerLoadMap(cart?.items ?? []).catch(handleError);
});

/**
 * Get the price per load for a given variant ID.
 * Returns null if the variant ID has not been mapped yet.
 * The 'price per load' values are fetched asynchronously
 * when line items are added to the cart.
 * Since it uses a Preact signal, Preact components that use this function
 * will automatically re-render once the value is loaded.
 */
export function getPricePerLoad(variantId: number): number | null {
  return pricePerLoadMap.value.get(variantId) ?? null;
}

/**
 * Update the price per load map with the given items.
 * Only updates items that are not already in the map.
 */
async function updatePricePerLoadMap(items: CartLineItem[]) {
  const currentMap = pricePerLoadMap.peek();
  const entries: Promise<PricePerLoadEntry>[] = [];
  for (const item of items) {
    if (currentMap.has(item.id)) continue;

    const getEntry = async () =>
      [item.id, await getPricePerLoadHandler(item)] as PricePerLoadEntry;

    entries.push(getEntry());
  }

  const prices = await Promise.all(entries);
  pricePerLoadMap.value = new Map([...currentMap, ...prices]);
}

/**
 * Get the price per load for a given cart item.
 * Divides price by the number of loads.
 * Returns null if the item does not have a `number_of_loads` metafield.
 */
export async function getPricePerLoadHandler(
  item: CartLineItem
): Promise<number | null> {
  const {product_id, options_with_values, price} = item;
  const input = {
    key: 'number_of_loads',
    namespace: 'custom',
    productId: product_id,
    selectedOptions: options_with_values,
  };
  const numberOfLoads = await getVariantMetafieldValue<string>(input);

  if (numberOfLoads === null) return null;

  return price / 100 / parseInt(numberOfLoads);
}

type PricePerLoadEntry = [number, number | null];
