import {setLocalizedStrings} from '@/localization/localization-store';
import {LocalizedStrings} from '@/localization/types';
import register from 'preact-custom-element';

/**
 * Loads localized strings into the application.
 * This allows us to pass localzied strings from liquid to the application.
 * The strings are stored as key-value pairs in a global localization store.
 * This allows any component to access the localized strings.
 * Multiple instances of this component can be used to load content
 * for specific snippets or sections in the theme.
 *
 * @param strings - The partial object containing localized strings.
 * @returns null
 * @example Load localized strings in liquid for use in the application.
 * ```liquid
 * <localized-string-loader
 *  country-iso-code="{{ localization.country.iso_code }}"
 *  language-iso-code="{{ localization.language.iso_code }}"
 * ></localized-string-loader>
 * ```
 *
 * @example Access localized strings in a component.
 * ```tsx
 * import {getLocalizedStrings} from '@/localization/localization-store';
 * const strings = getLocalizedStrings();
 * console.log(strings.countryIsoCode); // Output: 'US'
 * ```
 */
function LocalizedStringLoader(strings: Partial<LocalizedStrings>) {
  setLocalizedStrings(strings);
  return null;
}

register(LocalizedStringLoader, 'localized-string-loader', []);
