import franceFlag from '@/icons/france-flag-icon.svg';
import ukFlag from '@/icons/uk-flag-icon.svg';
import usaFlag from '@/icons/usa-flag-icon.svg';

const flagPresets = [
  {
    icon: {
      url: usaFlag,
      alt: 'United States Flag Icon',
    },
    locale: {
      country: 'US',
      language: 'en',
    },
  },
  {
    icon: {
      url: ukFlag,
      alt: 'United Kingdom Flag Icon',
    },
    locale: {
      country: 'GB',
      language: 'en',
    },
  },
  {
    icon: {
      url: franceFlag,
      alt: 'France Flag Icon',
    },
    locale: {
      country: 'FR',
      language: 'fr',
    },
  },
];

export type FlagButtonGroupProps = {
  onClickFlag?: (locale: {country: string; language: string}) => void;
  selectedCountry: string;
  bodyText?: string;
};

export default function FlagButtonGroup({
  onClickFlag,
  selectedCountry,
  bodyText = 'For the best customer experience and pricing, visit our local online store.',
}: FlagButtonGroupProps) {
  const nonSelectedFlags = flagPresets.filter(
    ({locale}) => locale.country !== selectedCountry
  );

  return (
    <div className="flex flex-col gap-4">
      <span className="text-center text-sm">{bodyText}</span>
      <div className="flex flex-wrap gap-8 self-center">
        {nonSelectedFlags.map(({locale, icon}) => (
          <button
            key={locale.country}
            onClick={(e) => {
              e.preventDefault();
              onClickFlag?.(locale);
            }}
          >
            <img src={icon.url} alt={icon.alt} className="h-6" />
          </button>
        ))}
      </div>
    </div>
  );
}
