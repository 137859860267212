import {datadogRum} from '@datadog/browser-rum';
import register from 'preact-custom-element';

/**
 * Checks the feature flag and adds the evaluation to Datadog.
 * This is a utility component to parse feature flag evaluations from liquid.
 * Should be loaded on every page where the 'get-feature-flag' liquid snippet is used.
 * @param flag - The name of the feature flag.
 * @param value - The value of the feature flag.
 * @returns null.
 */
function CheckFeatureFlag({flag, value}: {flag: string; value: string}) {
  // Flag is not evaluated unless a flag is provided.
  if (flag) {
    // Value may be empty, in which case it defaults to false.
    datadogRum.addFeatureFlagEvaluation(flag, value ?? false);
  }
  return null;
}

register(CheckFeatureFlag, 'check-feature-flag', ['flag', 'value']);
