import {client} from '@/shopify-storefront/client';
import {
  GetVariantMetafieldInput,
  VariantMetafieldValueResponse,
} from '@/product/types';

const getVariantMetafieldQuery = `
query getVariantMetafield($key: String!, $namespace: String!, $productId: ID!, $selectedOptions: [SelectedOptionInput!]!) {
     product(id: $productId) {
        variantBySelectedOptions(selectedOptions: $selectedOptions) {
            metafield(namespace: $namespace, key: $key) {
                value
            }
        }
    }
}
`;

/**
 * Get the value of a metafield on a variant.
 * Fetches the product variant using the Storefront API [client].
 * `productId` and `selectedOptions` are used to identify the variant.
 * `key` and `namespace` are used to identify the metafield.
 * If the product, variant, or metafield are not found, resolves to `null`.
 *
 * **NOTE:** Storefront API may return a metafield value that is a different type than the one defined in the schema.
 * For example, an 'integer' metafield may be returned as a 'string'.
 * Check the type of the returned value for each use case.
 * {@link https://shopify.dev/docs/api/storefront/2023-10/objects/Product#field-product-variantbyselectedoptions}
 * {@link https://shopify.dev/docs/api/storefront/2023-10/objects/ProductVariant#field-productvariant-metafield}
 */
export async function getVariantMetafieldValue<T>({
  key,
  namespace,
  productId,
  selectedOptions,
}: GetVariantMetafieldInput): Promise<T | null> {
  const data = await client.query<VariantMetafieldValueResponse<T>>(
    getVariantMetafieldQuery,
    {
      key,
      namespace,
      productId: `gid://shopify/Product/${productId}`,
      selectedOptions,
    }
  );
  return (
    (data.product?.variantBySelectedOptions?.metafield?.value as T) ?? null
  );
}
