import {getCart} from '@/cart/cart-store';

const CartItemsCount = () => {
    const cart = getCart();
    return (
        <>{cart?.item_count ?  cart?.item_count : ''}</>
    )
}

export default CartItemsCount;
