import {endDrawer, startDrawer} from '@/components/drawers';
import {getFeatureFlag} from '@/feature-flags/feature-flags-store';
import {useNavigate} from '@/hooks/use-navigate';

const navbarBurger = document.getElementById('navbar-burger');
navbarBurger?.addEventListener('click', () => startDrawer.open());

const cartIconButtons = document.querySelectorAll('[id*="cart-icon-button"]');
for (const button of cartIconButtons) {
  button.addEventListener('click', () => {
    const isCartPageFlag = getFeatureFlag('cart-page') === 'true';
    const isPostAtcFlag = getFeatureFlag('post-atc') === 'true';
    const shouldGoToCartPage = isCartPageFlag || isPostAtcFlag;

    if (shouldGoToCartPage) {
      const navigate = useNavigate();
      navigate('/cart');
    } else {
      endDrawer.open();
    }
  });
}
