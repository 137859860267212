import Modal, {ModalController} from '@/components/Modal';
import {getLocalizedStrings} from '@/localization/localization-store';
import {Country, ShopLocale} from '@/localization/types';
import {createPortal} from 'preact/compat';
import {useRef, useState} from 'preact/hooks';
import {startDrawer} from '@/components/drawers';
import FlagButtonGroup from '@/components/locale-dialog/FlagButtonGroup';

export type LocaleDialogProps = {
  bodyText: string;
  controller: ModalController;
  countryLabel: string;
  countryOptions: Country[];
  languageLabel: string;
  languageOptions: ShopLocale[];
  onSubmit?: (country: string, language: string) => void;
  selectedCountry: string;
  selectedLanguage: string;
  title: string;
  submitButtonLabel: string;
};

/**
 * A dialog for selecting a country and language.
 * @param controller - The controller for the dialog.
 * @param countryLabel - The label for the country selector.
 * @param countryOptions - The available country options.
 * @param languageLabel - The label for the language selector.
 * @param languageOptions - The available language options.
 * @param onChangeCountry - A callback for when the country is changed.
 * @param onChangeLanguage - A callback for when the language is changed.
 * @param selectedCountry - The currently selected country.
 * @param selectedLanguage - The currently selected language.
 * @param submitButtonLabel - The label for the submit button.
 * @param title - The title of the dialog.
 * @returns The locale dialog.
 */
export default function LocaleDialog({
  bodyText,
  controller,
  countryLabel = 'Country/region',
  countryOptions,
  languageLabel = 'Language',
  languageOptions,
  selectedCountry,
  selectedLanguage,
  submitButtonLabel = 'SAVE',
  title = 'Locale Settings',
}: LocaleDialogProps) {
  const [country, setCountry] = useState(selectedCountry);
  const [language, setLanguage] = useState(selectedLanguage);
  const form = useRef<HTMLFormElement>(null);
  const isDirty = country !== selectedCountry || language !== selectedLanguage;
  const rootRoute = getLocalizedStrings().storeRootRoute;

  const handleSubmit = () => {
    controller.close();
    startDrawer.close();
  };

  const handleClickFlag = (locale: {country: string; language: string}) => {
    const countryIsAvailable = countryOptions.some(
      (c) => c.isoCode === locale.country
    );
    if (countryIsAvailable) {
      setCountry(locale.country);
    }

    const languageIsAvailable = languageOptions.some(
      (l) => l.isoCode === locale.language
    );
    if (languageIsAvailable) {
      setLanguage(locale.language);
    }

    // allow form to re-render with new values before submitting
    setTimeout(() => {
      form.current?.submit();
      handleSubmit();
    }, 0);
  };

  // If the country changes, return to the root route.
  // This is to avoid 404 errors on market-specific product pages
  const isCountryChange = country !== selectedCountry;
  const returnTo = isCountryChange ? rootRoute : window.location.href;

  return createPortal(
    <Modal controller={controller}>
      <form
        acceptCharset="UTF-8"
        action={`${rootRoute?.replace(/\/$/, '')}/localization`}
        className="flex h-full flex-col justify-center gap-y-6"
        encType="multipart/form-data"
        id="localization_form"
        method="post"
        ref={form}
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form_type" value="localization" />
        <input type="hidden" name="utf8" value="✓" />
        <input type="hidden" name="_method" value="put" />
        <input type="hidden" name="return_to" value={returnTo} />
        <h3 className="text-center text-color-primary-2">{title}</h3>
        <div className="flex flex-col gap-y-3">
          {languageOptions.length > 1 && (
            <label
              htmlFor="language-selector"
              className="flex flex-col gap-y-2"
            >
              <span className="text-sm font-bold text-color-primary-2">
                {languageLabel}
              </span>
              <select
                id="language-selector"
                name="language_code"
                value={language}
                onChange={(e) => setLanguage(e.currentTarget.value)}
              >
                {languageOptions.map((language) => (
                  <option key={language.isoCode} value={language.isoCode}>
                    {language.endonymName}
                  </option>
                ))}
              </select>
            </label>
          )}
          {countryOptions.length > 1 && (
            <label htmlFor="country-selector" className="flex flex-col gap-y-2">
              <span className="text-sm font-bold text-color-primary-2">
                {countryLabel}
              </span>
              <select
                id="country-selector"
                name="country_code"
                value={country}
                onChange={(e) => setCountry(e.currentTarget.value)}
              >
                {countryOptions.map((country) => (
                  <option key={country.isoCode} value={country.isoCode}>
                    {getFlagEmoji(country.isoCode)} {country.name} (
                    {country.currency.isoCode} {country.currency.symbol})
                  </option>
                ))}
              </select>
            </label>
          )}
        </div>

        <button className="btn btn-primary mt-3 w-full" disabled={!isDirty}>
          {submitButtonLabel}
        </button>
        <FlagButtonGroup
          onClickFlag={handleClickFlag}
          selectedCountry={selectedCountry}
          bodyText={bodyText}
        />
      </form>
    </Modal>,
    document.body
  );
}

/**
 * Get the flag emoji for a given country code.
 * @param countryCode - The ISO 3166-1 alpha-2 country code.
 * @returns The flag emoji for the given country code.
 */
function getFlagEmoji(countryCode: string) {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}
