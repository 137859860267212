import {logError} from "@/utilities/log";
import { JSX } from "preact";
import { useState } from "preact/hooks";
import isEmail from 'validator/es/lib/isEmail';

export default NewsletterForm;

/**
 * Preact newsletter form component.
 * Takes a users email and signs them up for the newsletter
 * @returns  the newsletter component
 */
function NewsletterForm({ invalidEmailErr, newsletterReqErr, successMsg, onFormSubmit }: NewsletterProps) {
  const [email, setEmail] = useState<string>("");
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true);
  const [reqFailed, setReqFailed] = useState<boolean>(false)
  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false)

  const onSubmit = (e: JSX.TargetedEvent<HTMLFormElement, Event>) => {
    e.preventDefault();
    const isValid = (isEmail as (email: string) => boolean)(email);
    if(!isValid) {
      setIsValidEmail(isValid)
      setTimeout(() => { setIsValidEmail(true) }, 2000);
    } else {
      onFormSubmit(email)
         .then(() => {
          setShowSuccessMsg(true);
        })
        .catch((e) => {
          logError(e, {email});
          setReqFailed(true);
          setTimeout(() => { setReqFailed(false) }, 2000);
        })
    }
  }

  return (
    <>
     <form onSubmit={e => onSubmit(e)} className="flex flex-col">
        <div className="flex justify-center gap-4 md:justify-start lg:justify-center" >
          { showSuccessMsg ? (
            <span className="text-2xl font-bold text-dark-blue-gray" >{successMsg}</span>
          ) : (
            <>
              <input
                className="h-12 w-full rounded border-primary-teal pb-3.5 pl-4 pr-3.5 pt-3 sm:w-60 md:w-full lg:w-82"
                type="email"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail((e?.target as HTMLInputElement).value || '')}
                autoComplete="email"
                aria-label="email"
              />
              <button
                className="btn btn-square btn-teal"
                type="submit"
              >
                Subscribe
              </button>
            </>
          )}
        </div>

        { reqFailed || !isValidEmail ? (
          <p className="animate-fadeOut text-center text-xs font-bold text-accent-red">
            { reqFailed ? newsletterReqErr : null}
            { !isValidEmail ? invalidEmailErr : null}
          </p>
        ) : null}
      </form>
    </>
  );
}

export type NewsletterProps = {
  invalidEmailErr: string,
  newsletterReqErr: string,
  successMsg: string,
  onFormSubmit: (email: string) => Promise<Response>
};

