export default LineItemDetailLegacy;
import {isRecurringLineItem} from '@/cart/line-item';
import {getPricePerLoad} from '@/cart/price-per-load';
import {CartLineItem} from '@/cart/types';
import QuantitySelector from '@/components/QuantitySelector';
import {getMappedOnePackPrice} from '@/experiments/price-test/price-test';
import {getFeatureFlag} from '@/feature-flags/feature-flags-store';
import closeIcon from '@/icons/close-icon.svg';
import {getLocalizedStrings} from '@/localization/localization-store';
import {SellingPlanSelectorOption} from '@/selling-plan/types';
import {createShopifyImageUrl} from '@/utilities/create-shopify-image-url';
import formatCurrency from '@/utilities/format-currency';

/**
 * This component displays the details of a single line item in the cart.
 * It is intended to be used as a child of [CartSection].
 */
function LineItemDetailLegacy({
  item,
  currency,
  locale,
  onUpdateQuantity,
}: LineItemDetailLegacyProps) {
  // If the price test is active, adjust the savings percentage
  // to reflect the test onetime price
  const showPriceTest = getFeatureFlag('otp-price') === 'true';

  const {
    featured_image: {url: src, alt},
    id,
    discounted_price: price,
    quantity,
    product_title,
    variant_title: variantTitle,
    selling_plan_allocation,
    original_price,
    properties,
  } = item;
  const strings = getLocalizedStrings();
  const priceFormatted =
    price === 0 ? strings.free : formatCurrency(price / 100, currency, locale);
  const originalPriceFormatted = formatCurrency(
    original_price / 100,
    currency,
    locale
  );

  const pricePerLoadLabel = (price: number) =>
    `(${formatCurrency(price, currency, locale)} per Load)`;

  const pricePerLoad = getPricePerLoad(id);

  /**
   * Handles the removal of an item from the cart
   * Calls the onUpdateQuantity callback with a quantity of 0
   */
  const handleRemoveItem = () => {
    if (onUpdateQuantity && quantity !== 0) {
      onUpdateQuantity(0);
    }
  };

  const basePrice = getMappedOnePackPrice();
  const mappedSavings = Math.round((1 - price / 100 / basePrice) * 100);

  const percentage =
    showPriceTest && basePrice
      ? mappedSavings
      : selling_plan_allocation?.selling_plan?.price_adjustments[0].value; // returns 40 for a 40% price adjustment

  const maxQuantityProperty = properties?._max_quantity;
  const maxQuantity = maxQuantityProperty
    ? parseInt(maxQuantityProperty)
    : undefined;
  return (
    <div className="grid grid-cols-[minmax(0,1fr)_minmax(0,_2fr)] items-center gap-x-3 px-6 py-3">
      <img
        className="col-span-1 row-start-1 row-end-5 mb-3 h-auto w-full self-start"
        src={createShopifyImageUrl(src, 160)}
        alt={alt}
        width={96}
        height={144}
      />
      <div>
        <div className="grid-cols-grow-max grid items-start gap-x-1">
          <div className="grid">
            <span className="font-semibold leading-normal">
              {product_title}
            </span>
            {percentage && isRecurringLineItem(item) ? (
              <span className="font-semibold leading-normal">
                {percentage}% Off Auto Renew
              </span>
            ) : null}
          </div>
          <button
            onClick={handleRemoveItem}
            className="btn btn-neutral rounded-full p-0"
          >
            <img
              src={closeIcon}
              alt="Remove item"
              height="21"
              width="21"
              className="size-5"
            />
          </button>
        </div>

        <p className="text-sm text-dark-blue-gray">{variantTitle}</p>
        {original_price > price && (
          <span className="block text-base font-semibold leading-tight text-gray line-through">
            {originalPriceFormatted}
          </span>
        )}
        <span className="text-xl font-bold text-dark-true-black">
          {priceFormatted}
        </span>
      </div>

      <div className="row-span-3"></div>

      {(!maxQuantity || (maxQuantity && maxQuantity > 1)) && (
        <QuantitySelector
          value={quantity}
          onUpdate={onUpdateQuantity}
          max={maxQuantity}
        />
      )}
      {pricePerLoad != null && (
        <div className="flex h-full items-end">
          <span className="text-2xs font-black text-dark-blue-gray">
            {pricePerLoadLabel(pricePerLoad)}
          </span>
        </div>
      )}
    </div>
  );
}

type LineItemDetailLegacyProps = {
  currency?: string;
  item: CartLineItem;
  locale?: string;
  onUpdateQuantity?: (quantity: number) => void;
  sellingPlanOptions?: SellingPlanSelectorOption[];
};
